export const $ = (selector: string): HTMLElement => {
    const element: HTMLElement | null = document.querySelector(selector);
    if (!element) throw new Error(`Element ${selector} not found`);
    return element;
};

export const $$ = (selector: string): NodeListOf<HTMLElement> => {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll(selector);
    if (!elements.length) throw new Error(`Elements ${selector} not found`);
    return elements;
};

export const sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatBytes = (bytes: number, decimals: number = 2): string => {
    if (bytes === 0) return '0 B';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const sendFile = async (url: string, method: string = 'GET', headers: any, body: any, onProgress?: (e: ProgressEvent) => void): Promise<any> => {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.upload.addEventListener('progress', (e) => onProgress && onProgress(e));
        request.addEventListener('load', () => resolve(request));
        request.addEventListener('error', (e) => reject(e));
        request.addEventListener('abort', (e) => reject(e));
        request.open(method, url);
        for (const key in headers) {
            request.setRequestHeader(key, headers[key]);
        }
        request.send(body);
    });
};

export const receiveFile = async (url: string, method: string = 'GET', headers: any, onProgress: (received: number) => void): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.addEventListener('progress', (e) => onProgress(e.loaded));  
        request.addEventListener('load', () => resolve(request.response));
        request.addEventListener('error', (e) => reject(e));
        request.addEventListener('abort', (e) => reject(e));
        request.open(method, url);
        for (const key in headers) {
            request.setRequestHeader(key, headers[key]);
        }
        request.responseType = 'blob';
        request.send();
    });
};