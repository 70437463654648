import Metadata from "./metadata";
import { receiveFile, sendFile } from "./utils";

const STORAGE_URL: string = 'https://files.kalucki23.workers.dev/';
const CDN_URL: string = 'https://filez.b-cdn.net/';

export const createMetadata = async (files: File[], parts: Blob[]): Promise<Metadata> => {
    let offset = 0;
    const data = {
        parts: parts.map((part) => part.size),
        files: files.map((file, i) => {
            offset += file.size;
            return {
                name: file.name,
                size: file.size,
                offset: offset - file.size,
            };
        })
    };
    const response = await fetch(`${STORAGE_URL}metadata`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error('Failed to prepare upload');
    }
    const json = await response.json();
    const metadata = Metadata.fromJson(json);
    return metadata;
};

export const getMetadata = async (uid: string): Promise<Metadata> => {
    const response = await fetch(`${STORAGE_URL}metadata/${uid}`);
    if (!response.ok) {
        throw new Error('Failed to get metadata');
    }
    const json = await response.json();
    const metadata = Metadata.fromJson(json);
    return metadata;
};

export const uploadFile = async (file: Blob, uid: string, onProgress: (progress: number) => void): Promise<string> => {
    const response = await sendFile(STORAGE_URL, 'PUT', {
        'Content-Type': 'application/octet-stream',
        'X-Id': uid,
    }, file, (e) => {
        const progress = e.loaded / e.total;
        onProgress(progress * .99);
    });
    if (response.status !== 201) {
        throw new Error('Failed to upload file');
    }
    onProgress(1);
    const json = JSON.parse(response.responseText);
    if (!json.id) {
        throw new Error('Failed to upload file');
    }
    return json.id;
};

export const downloadFiles = async (metadata: Metadata, onProgress: (progress: number) => void): Promise<Blob> => {
    const totalSize = metadata.parts.reduce((acc, part) => acc + part, 0);
    let totalProgress = 0, prevProgress = 0, blob = new Blob();
    for (let i = 0; i < metadata.parts.length; i++) {
        const part = await receiveFile(`${CDN_URL}${metadata.uid}/${i}.part`, 'GET', {}, (received) => {
            totalProgress = prevProgress + (received / totalSize);
            onProgress(totalProgress);
        });
        prevProgress = totalProgress;
        blob = new Blob([blob, part]);
    }
    return blob;
};

export const downloadFile = async (uid: string, parts: { part: number, start: number, end: number }[], onProgress: (progress: number) => void): Promise<Blob> => {
    const totalSize = parts.reduce((acc, part) => acc + (part.end - part.start), 0);    
    let totalProgress = 0, prevProgress = 0, blob = new Blob();
    for (let i = 0; i < parts.length; i++) {
        const part = await receiveFile(`${CDN_URL}${uid}/${parts[i].part}.part`, 'GET', {
            'Range': `bytes=${parts[i].start}-${parts[i].end}`,
        }, (received) => {
            totalProgress = prevProgress + (received / totalSize);
            onProgress(totalProgress);
        });
        prevProgress = totalProgress;
        blob = new Blob([blob, part]);
    }
    return blob;
};