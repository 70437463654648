export default class Metadata {
    uid: string;
    exp: string;
    files: {
        name: string;
        size: number;
        offset: number;
    }[];
    parts: number[];

    static fromJson(json: any): Metadata {
        const metadata = new Metadata();
        metadata.uid = json.uid;
        metadata.exp = json.exp;
        metadata.files = json.files;
        metadata.parts = json.parts;
        return metadata;
    }
}