import { replaceFiles, updateDownloadBtn, updateUI } from ".";
import Metadata from "./metadata";
import { downloadFile, downloadFiles, getMetadata } from "./storage";
import { sleep } from "./utils";

let metadata: Metadata | null = null;

const splitBlob = (blob: Blob, files: { name: string, size: number, offset: number }[]): File[] => {
    const parts: File[] = [];
    let offset = 0;
    for (const file of files) {
        const chunk = blob.slice(offset, offset + file.size);
        parts.push(new File([chunk], file.name));
        offset += file.size;
    }
    return parts;
};

const downloadFromUrl = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    a.click();
    a.remove();
};

const getFileParts = (metadata: Metadata, index: number): { part: number, start: number, end: number }[] => {
    const file = metadata.files[index];
    const parts: { part: number, start: number, end: number }[] = [];
    const maxPartSize = 96_000_000;

    let startPart = Math.floor(file.offset / maxPartSize);
    let endPart = Math.floor((file.offset + file.size) / maxPartSize);

    let bytesLeft = file.size;
    for (let partIndex = startPart; partIndex <= endPart; partIndex++) {
        const fileStart = Math.max(0, file.offset - (partIndex * maxPartSize));
        const fileEnd = Math.min(maxPartSize, bytesLeft + fileStart);
        bytesLeft -= fileEnd - fileStart;
        parts.push({
            part: partIndex,
            start: fileStart,
            end: fileEnd - 1,
        });
    }

    return parts;
}

export const handleDownload = async (uid: string): Promise<void> => {
    updateUI();

    metadata = await getMetadata(uid);
    console.log(metadata);
    replaceFiles(metadata.files.map((file) => ({ name: file.name, size: file.size })));

    updateUI();
};

export const downloadSingle = async (index: number): Promise<void> => {
    if (!metadata) {
        throw new Error('Metadata not found');
    }
    const file = metadata.files[index];
    const parts = getFileParts(metadata, index);

    updateDownloadBtn('0%');

    const fileBlob = await downloadFile(metadata.uid, parts, (progress) => {
        updateDownloadBtn(Math.round(progress * 100) + '%');
    });

    updateDownloadBtn('100%');
    await sleep(100);
    updateDownloadBtn('Download');

    const url = URL.createObjectURL(fileBlob);
    downloadFromUrl(url, file.name);
    URL.revokeObjectURL(url);
};

export const downloadAll = async (): Promise<void> => {
    if (!metadata)
        throw new Error('Metadata not found');

    updateDownloadBtn('0%');

    const filesBlob = await downloadFiles(metadata, (progress) => {
        updateDownloadBtn(Math.round(progress * 100) + '%');
    });

    updateDownloadBtn('100%');
    await sleep(100);

    const files = splitBlob(filesBlob, metadata.files);

    await sleep(100);
    updateDownloadBtn('Download all');

    for (const file of files) {
        const url = URL.createObjectURL(file);
        downloadFromUrl(url, file.name);
        URL.revokeObjectURL(url);
        await sleep(50);
    }
};