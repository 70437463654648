// @ts-nocheck
import fileSvg from 'bundle-text:../img/icons/file.svg';
import trashSvg from 'bundle-text:../img/icons/trash.svg';
import downloadSvg from 'bundle-text:../img/icons/download.svg';

const createElement = (html: string): HTMLElement => {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.firstElementChild as HTMLElement;
};

export const fileIcon = createElement(fileSvg);
export const trashIcon = createElement(trashSvg);
export const downloadIcon = createElement(downloadSvg);
