import ldBar from '@loadingio/loading-bar';
import { $, formatBytes } from './utils';
import { startUploading, updateUI } from '.';
import { createMetadata, uploadFile } from './storage';

const statusText: HTMLElement = $('#status');
const urlField: HTMLElement = $('.url span');

let progressBar: ldBar | null = null;
let uploadProgress: number = 0;

const updateStatus = (msg: string): void => {
    statusText.textContent = msg;
};

const finishUpload = (msg: string): void => {
    window.onbeforeunload = null;
    window.onfocus = null;
    updateStatus(msg);
    updateUI();
};

const updateProgress = (value: number): void => {
    if (value === 0) return;
    document.title = `${value}% | File Sharing`;
    if (progressBar === null) {
        progressBar = new ldBar('.progress', { value: 0 });
    }
    progressBar.set(value);
};

const combineFiles = (files: File[]): Blob => {
    const blob = new Blob(files, { type: 'application/octet-stream' });
    return blob;
};

const splitBySize = (blob: Blob, size: number): Blob[] => {
    const parts: Blob[] = [];
    let offset = 0;
    while (offset < blob.size) {
        const chunk = blob.slice(offset, offset + size);
        parts.push(chunk);
        offset += size;
    }
    return parts;
};

const getTotalSize = (files: File[]): string => {
    const total = files.reduce((acc, file) => acc + file.size, 0);
    return formatBytes(total);
};

const logUploadSpeed = (files: File[], start: number): void => {
    const now = new Date().getTime();
    const elapsed = now - start;
    const total = files.reduce((acc, file) => acc + file.size, 0);
    const speed = formatBytes(total / (elapsed / 1000));
    console.log(`Uploaded ${total} bytes in ${elapsed}ms (${speed}/s)`);
};

const uploadFiles = async (files: File[]): Promise<void> => {
    startUploading();

    window.onbeforeunload = (): boolean => true;

    updateStatus(`Uploading ${files.length} files (${getTotalSize(files)})`);

    urlField.textContent = 'Starting...';

    updateUI();

    const blob = combineFiles(files);
    const parts = splitBySize(blob, 1000 * 1000 * 96);

    const metadata = await createMetadata(files, parts);
    urlField.textContent = `${location.origin}/${metadata.uid}`;

    const uploadStart = new Date().getTime();
    const multiplier = 100 / parts.length;
    let prevProgress = 0;

    try {
        for (const part of parts) {
            await uploadFile(part, metadata.uid, (progress: number) => {
                uploadProgress = Math.round(progress * multiplier) + prevProgress;
                updateProgress(uploadProgress);
            });
            prevProgress = uploadProgress;
        }
        logUploadSpeed(files, uploadStart);
        updateProgress(100);
        finishUpload('Upload complete');
    } catch (e) {
        console.error(e);
        finishUpload('Upload failed');
    }
};

window.onfocus = () => updateProgress(uploadProgress);

export {
    uploadFiles
};