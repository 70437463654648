import { $, $$, formatBytes } from './utils';
import { downloadAll, downloadSingle, handleDownload } from './download';
import { trashIcon, fileIcon, downloadIcon } from './icons';
import { uploadFiles } from './upload';

const uploadTab: HTMLElement = $('.upload-tab');
const queueTab: HTMLElement = $('.queue-tab');
const uploadingTab: HTMLElement = $('.uploading-tab');

const filesList: HTMLElement = $('.files');
const dropArea: HTMLElement = $('main');
const browseButtons: NodeListOf<HTMLElement> = $$('.browse');
const fileInput: HTMLInputElement = $('#file-input') as HTMLInputElement;
const cancelBtn: HTMLElement = $('#cancel');
const uploadBtn: HTMLElement = $('#upload');
const downloadBtn: HTMLElement = $('#download');

const filesQueue: File[] | { name: string, size: number }[] = [];
let isUploading: boolean = false;
let isDownload: boolean = false;

const filesId = document.body.getAttribute('id') || location.hash.slice(1);

const setTab = (tab: HTMLElement): void => {
    uploadTab.classList.add('hidden');
    queueTab.classList.add('hidden');
    uploadingTab.classList.add('hidden');

    tab.classList.remove('hidden');
};

const disableDownload = (): void => {
    for (const button of $$('.file svg')) {
        button.style.pointerEvents = 'none';
    }
    downloadBtn.style.pointerEvents = 'none';
};

const enableDownload = (): void => {
    for (const button of $$('.file svg')) {
        button.style.pointerEvents = 'auto';
    }
    downloadBtn.style.pointerEvents = 'auto';
};

const buildFileRow = (file: File, i: number): HTMLElement => {
    const row = document.createElement('div');
    row.dataset.id = i.toString();
    row.classList.add('file');

    const fileSvg = fileIcon.cloneNode(true);
    row.appendChild(fileSvg);

    const fileName = document.createElement('p');
    fileName.appendChild(document.createTextNode(file.name));
    row.appendChild(fileName);

    const size = formatBytes(file.size);
    const fileSize = document.createElement('p');
    fileSize.appendChild(document.createTextNode(size));
    row.appendChild(fileSize);

    if (isDownload) {
        const downloadSvg = downloadIcon.cloneNode(true);
        downloadSvg.addEventListener('click', async () => {
            disableDownload();
            await downloadSingle(i);
            enableDownload();
        });
        row.appendChild(downloadSvg);
    } else {
        const trashSvg = trashIcon.cloneNode(true);
        trashSvg.addEventListener('click', () => {
            filesQueue.splice(i, 1);
            updateUI();
        });
        row.appendChild(trashSvg);
    }
    return row;
};

const updateUI = (): void => {
    if (isUploading) {
        setTab(uploadingTab);
    } else if (filesQueue.length > 0 || isDownload) {
        setTab(queueTab);
    } else {
        setTab(uploadTab);
    }

    if (isDownload) {
        browseButtons.forEach(button => button.classList.add('hidden'));
        cancelBtn.classList.add('hidden');
        uploadBtn.classList.add('hidden');
        downloadBtn.classList.remove('hidden');
    } else {
        browseButtons.forEach(button => button.classList.remove('hidden'));
        cancelBtn.classList.remove('hidden');
        uploadBtn.classList.remove('hidden');
        downloadBtn.classList.add('hidden');
    }

    filesList.replaceChildren();

    (filesQueue as File[]).forEach((file: File, i: number) => {
        const row = buildFileRow(file, i);
        filesList.appendChild(row);
    });
};

const handleDrop = (e: DragEvent): void => {
    const transfer = e.dataTransfer;
    if (!transfer || isUploading) return;
    const files = transfer.files;

    if (isDownload) {
        isDownload = false;
        location.hash = '';
        filesQueue.length = 0;
    }

    filesQueue.push(...files);

    updateUI();
};

const preventDefaults = (e: Event): void => {
    e.preventDefault();
    e.stopPropagation();
};

const highlight = (): void => {
    if (!isUploading)
        dropArea.classList.add('highlight');
};

const unhighlight = (): void => dropArea.classList.remove('highlight');

const loadDownload = async (): Promise<void> => {
    if (filesId.length >= 8 && filesId.length <= 10) {
        isDownload = true;
        try {
            handleDownload(filesId);
        } catch (e) {
            console.error(e);
        }
    }
};

loadDownload();

window.onload = (): void => {
    console.log('file_hosting v1.3.0');

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false)
    });

    ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false)
    });

    ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false)
    });

    browseButtons.forEach(button => {
        button.addEventListener('click', () => {
            fileInput.click();
        });
    });

    fileInput.addEventListener('change', () => {
        const files = fileInput.files;
        if (!files || isUploading) return;

        if (isDownload) {
            isDownload = false;
            location.hash = '';
            filesQueue.length = 0;
        }

        filesQueue.push(...files);
        fileInput.value = '';

        updateUI();
    });

    dropArea.addEventListener('drop', handleDrop, false);

    cancelBtn.addEventListener('click', () => {
        filesQueue.length = 0;
        updateUI();
    });

    uploadBtn.addEventListener('click', async () => {
        await uploadFiles(filesQueue as File[]);
    });

    downloadBtn.addEventListener('click', async () => {
        disableDownload();
        await downloadAll();
        enableDownload();
    });
};

const startUploading = (): boolean => isUploading = true;

const replaceFiles = (files: { name: string, size: number }[]): void => {
    filesQueue.length = 0;
    // @ts-ignore
    filesQueue.push(...files);
};

const updateDownloadBtn = (text: string): void => {
    downloadBtn.textContent = text;
};

export {
    updateUI,
    replaceFiles,
    startUploading,
    updateDownloadBtn,
};